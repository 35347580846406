import { endOfDay, startOfDay } from 'date-fns';
import { types as t, Instance, flow } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { FacilityFormValues } from '../pages';
import { maybeNullReference } from '../types';
import { RoundingStep } from './RoundingStep';
import { User } from './User';
import { IssueType } from './IssueType';

const Assignment = t.model('Assignment', {
  id: t.identifierNumber,
  issue_types: t.array(maybeNullReference(IssueType)),
  rounding_types: t.array(t.string),
  user: maybeNullReference(User),
});

export const Facility = t
  .model('Facility', {
    id: t.identifierNumber,
    name: t.string,
    is_active: t.boolean,
    assignments: t.array(Assignment),
    rounding_steps: t.array(RoundingStep),
    meta_data: t.maybeNull(t.map(t.string)),
  })
  .extend(withRequest)
  .views((self) => {
    return {
      get filteredRoundingSteps() {
        return self.rounding_steps.filter((step) => step.deleted_at === null);
      },
    };
  })
  .actions((self) => {
    const { request } = self;

    return {
      deactivate: flow(function* () {
        try {
          yield request({
            method: 'patch',
            url: `/api/facilities/${self.id}/`,
            data: {
              is_active: false,
            },
          });
          self.is_active = false;
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
      save: flow(function* (formValues: FacilityFormValues) {
        const { facilityName, assignments, roundingSteps, metaData } =
          formValues;

        const stepsToAdd = roundingSteps
          .filter((rs) => !rs.isSaved)
          .map((s) => {
            return {
              name: s.name,
              setpoints: s.setpoints,
              instruction: s.instruction,
              from_dt: startOfDay(s.period.fromDate),
              to_dt: endOfDay(s.period.toDate),
              interval: Number(s.interval),
              interval_type: s.intervalType.value,
              company: s.company.value,
              rounding_type: s.roundingType.value,
            };
          });

        const stepsToRemove = self.filteredRoundingSteps.filter(
          (step) => !roundingSteps.some((s) => s.id === step.id),
        );

        const data = {
          name: facilityName,
          assignments,
          meta_data: metaData?.reduce(
            (prev, current) => ({
              ...prev,
              [current.key]: current.value,
            }),
            {},
          ),
        };

        try {
          // We need update the facility before we update the rounding steps,
          // to make sure the rounding steps gets the correct assignment.
          const {
            data: { name, assignments, meta_data },
          } = yield request({
            method: 'PATCH',
            url: `/api/facilities/${self.id}/`,
            data,
          });

          self.name = name;
          self.assignments = assignments;
          self.meta_data = meta_data;
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }

        const promises: Promise<any>[] = [];

        if (stepsToAdd.length > 0) {
          promises.push(
            request({
              method: 'POST',
              url: `/api/facilities/${self.id}/rounding_steps/`,
              data: stepsToAdd,
            }),
          );
        }

        stepsToRemove.forEach((step) => {
          promises.push(
            request({
              method: 'DELETE',
              url: `/api/facilities/${self.id}/rounding_steps/${step.id}`,
            }),
          );
        });

        try {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [stepsToAdd, ...rest] = yield Promise.all(promises);

          if (stepsToAdd?.data) {
            stepsToAdd.data.forEach((step: any) => {
              self.rounding_steps.push(step);
            });
          }

          stepsToRemove.forEach((step) => {
            step.deleted_at = new Date();
          });
        } catch (error) {
          // No need to handle this error since the general error
          // message will be shown to the user and they can try again.
        }
      }),
    };
  });

export type FacilityInstance = Instance<typeof Facility>;
export type AssignmentInstance = Instance<typeof Assignment>;
export const FacilityReference = maybeNullReference(Facility);
