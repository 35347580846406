import React, { useRef, UIEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, Route, Navigate, Routes } from 'react-router-dom';
import {
  Header,
  FadeInDiv,
  LogOut,
  CreateIssue,
  NotificationsList,
  SearchModal,
} from '../components';
import { SideMenu, MobileSideMenu } from '../components/side-menu';
import {
  OrganizationPage,
  ClosedIssuesPage,
  IssuePage,
  OpenIssuesPage,
  CreateUserPage,
  ParkingPage,
  RecurringParkingPage,
  Dashboard,
  ArticlesPage,
  CreateArticlePage,
  CompaniesPage,
  CreateCompanyPage,
  EditCompanyPage,
  EditUserPage,
  SearchPage,
  CreateFacilityPage,
  EditFacilityPage,
  FacilitiesPage,
  EditArticlePage,
  RoundsPage,
  ParkingRestrictionPage,
  ClosedRoundsPage,
} from '../pages';
import { useStore } from '../hooks';

export const LoggedInPage = observer(() => {
  const { pathname } = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);
  const {
    uiStore,
    organizationStore: { organization },
    notificationStore,
    userStore: { me },
  } = useStore();

  const handleScroll = (event: UIEvent) => {
    uiStore.checkIsShowingMobileHeader(
      (event.target as HTMLDivElement).scrollTop,
    );
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [pathname]);

  useEffect(() => {
    const interval = setInterval(notificationStore.fetchNewCount, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  if (organization === null || me === null) return null;

  const shouldShowIssues = uiStore.shouldShowIssues(organization, me);
  const shouldShowParking = uiStore.shouldShowParking(organization);
  const shouldShowRecurringParking = uiStore.shouldShowRecurringParking(
    organization,
    me,
  );
  const shouldShowArticles = uiStore.shouldShowArticles(organization);
  const shouldShowOrganization = uiStore.shouldShowOrganization(me);
  const shouldShowRounds = uiStore.shouldShowRounds(organization, me);

  // Depending on the organization addons and user permissions we should
  // redirect the logged in user to different default locations.
  let noMatchRedirect = '/';
  if (shouldShowIssues) {
    noMatchRedirect = '/dashboard';
  } else if (shouldShowParking) {
    noMatchRedirect = '/parking';
  }

  return (
    <FadeInDiv className="relative flex h-screen w-screen flex-1 overflow-hidden">
      {uiStore.isMobile ? <MobileSideMenu /> : <SideMenu />}

      <div
        className="flex flex-1 flex-col overflow-y-auto"
        onScroll={handleScroll}
        ref={contentRef}
      >
        <Header />
        <Routes>
          <Route path="/create-password" element={LogOut} />
          <Route path="/forgot-password" element={LogOut} />
          {shouldShowIssues && (
            <Route path="/dashboard" element={<Dashboard />} />
          )}
          {shouldShowIssues && (
            <Route path="/issues/mine/:issueId" element={<IssuePage />} />
          )}
          {shouldShowIssues && (
            <Route path="/issues" element={<OpenIssuesPage />} />
          )}
          {shouldShowIssues && (
            <Route path="/issues/complete" element={<ClosedIssuesPage />} />
          )}
          {shouldShowIssues && (
            <Route path="/issues/complete/:issueId" element={<IssuePage />} />
          )}
          {shouldShowIssues && (
            <Route path="/issues/:issueId" element={<IssuePage />} />
          )}

          {shouldShowArticles && (
            <Route path="/organization/articles" element={<ArticlesPage />} />
          )}

          {shouldShowArticles && (
            <Route
              path="/organization/articles/create-article"
              element={<CreateArticlePage />}
            />
          )}

          {shouldShowArticles && (
            <Route
              path="/organization/articles/edit-article/:articleId"
              element={<EditArticlePage />}
            />
          )}

          {shouldShowParking && (
            <Route path="/parking" element={<ParkingPage />} />
          )}
          {shouldShowRecurringParking && (
            <Route
              path="/parking/recurring"
              element={<RecurringParkingPage />}
            />
          )}

          {shouldShowOrganization && (
            <Route path="/organization/users" element={<OrganizationPage />} />
          )}
          {shouldShowOrganization && (
            <Route
              path="/organization/create-user"
              element={<CreateUserPage />}
            />
          )}
          {shouldShowOrganization && (
            <Route
              path="/organization/edit-user/:userId"
              element={<EditUserPage />}
            />
          )}
          {shouldShowOrganization && (
            <Route
              path="/organization/facilities/edit-facility/:facilityId"
              element={<EditFacilityPage />}
            />
          )}

          {shouldShowOrganization && (
            <Route
              path="/organization/facilities"
              element={<FacilitiesPage />}
            />
          )}
          {shouldShowOrganization && (
            <Route
              path="/organization/facilities/create-facility"
              element={<CreateFacilityPage />}
            />
          )}

          {shouldShowOrganization && (
            <Route path="/organization/companies" element={<CompaniesPage />} />
          )}
          {shouldShowOrganization && (
            <Route
              path="/organization/companies/create-company"
              element={<CreateCompanyPage />}
            />
          )}
          {shouldShowOrganization && (
            <Route
              path="/organization/companies/edit-company/:companyId"
              element={<EditCompanyPage />}
            />
          )}
          {shouldShowOrganization && (
            <Route
              path="/organization/parking"
              element={<ParkingRestrictionPage />}
            />
          )}

          {shouldShowRounds && (
            <Route path="/rounds" element={<RoundsPage />} />
          )}

          {shouldShowRounds && (
            <Route path="/rounds/complete" element={<ClosedRoundsPage />} />
          )}

          <Route path="/search" element={<SearchPage />} />

          {/* If there is no match this redirect will be used. */}
          <Route path="*" element={<Navigate to={noMatchRedirect} replace />} />
        </Routes>
      </div>

      <div
        className={`absolute right-0 z-50 h-screen w-full transform transition-transform md:w-auto ${
          uiStore.isCreateIssueOpen ? '' : 'translate-x-full'
        }`}
      >
        <CreateIssue />
      </div>

      <div
        className={`absolute right-0 z-50 h-screen w-full transform transition-transform md:w-auto ${
          uiStore.isNotificationListOpen ? '' : 'translate-x-full'
        }`}
      >
        <NotificationsList />
      </div>
      {uiStore.isSearchModalOpen && <SearchModal />}
    </FadeInDiv>
  );
});
