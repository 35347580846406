import { applySnapshot, types as t } from 'mobx-state-tree';
import { AccountType } from '../types';
import { OrganizationInstance } from './Organization';
import { UserInstance } from './User';

export const UIStore = t
  .model('UIStore', {
    isMenuExpanded: window.innerWidth < 768 || window.innerWidth > 1024,
  })
  .volatile(() => ({
    isMobileMenuOpen: false,
    isMobile: window.innerWidth < 768,
    isTablet: 768 <= window.innerWidth && window.innerWidth < 1024,
    isDesktop: 1024 <= window.innerWidth,
    isShowingMobileHeader: true,
    isShowingError: false,
    isDatepickerOpen: false,
    isCreateIssueOpen: false,
    isNotificationListOpen: false,
    isSearchModalOpen: false,
    isIssuesFilterPickerOpen: false,
    isRoundingFilterPickerOpen: false,
  }))
  .views(() => ({
    shouldShowIssues(organization: OrganizationInstance, user: UserInstance) {
      return organization.hasIssuesFeature && user.role > AccountType.Parking;
    },
    shouldShowParking(organization: OrganizationInstance) {
      return organization.hasParkingFeature;
    },
    shouldShowRecurringParking(
      organization: OrganizationInstance,
      user: UserInstance,
    ) {
      return organization.hasParkingFeature && user.role > AccountType.Parking;
    },
    shouldShowArticles(organization: OrganizationInstance) {
      return organization.hasIssueCostsFeature;
    },
    shouldShowOrganization(user: UserInstance) {
      return user.role > AccountType.Parking;
    },
    shouldShowParkingCSVExport(user: UserInstance) {
      return user.role > AccountType.Parking;
    },
    shouldShowRounds(organization: OrganizationInstance, user: UserInstance) {
      return organization.hasRoundsFeature && user.role > AccountType.Parking;
    },
  }))
  .actions((self) => {
    let currentScrollOffset = 0;

    return {
      checkDeviceResolution() {
        const { isMenuExpanded, isMobile, isTablet, isDesktop } = self;
        const becameMobile = window.innerWidth < 768;
        const becameTablet =
          768 <= window.innerWidth && window.innerWidth < 1024;
        const becameDesktop = 1024 <= window.innerWidth;

        if (isMobile !== becameMobile) {
          self.isMobile = becameMobile;
        }

        if (becameMobile && !isMenuExpanded) {
          self.isMenuExpanded = true;
          self.isTablet = false;
          self.isDesktop = false;
        } else if (!isTablet && becameTablet) {
          self.isMenuExpanded = false;
          self.isTablet = true;
          self.isDesktop = false;
        } else if (!isDesktop && becameDesktop) {
          self.isMenuExpanded = true;
          self.isTablet = false;
          self.isDesktop = true;
        }
      },
      toggleMenuExpanded() {
        self.isMenuExpanded = !self.isMenuExpanded;
      },
      setMenuExpanded(isMenuExpanded: boolean) {
        self.isMenuExpanded = isMenuExpanded;
      },
      openMobileMenu() {
        self.isMobileMenuOpen = true;
      },
      closeMobileMenu() {
        self.isMobileMenuOpen = false;
      },
      checkIsShowingMobileHeader(newScrollOffset: number) {
        if (!self.isMobile) return;

        if (currentScrollOffset > newScrollOffset) {
          self.isShowingMobileHeader = true;
        } else {
          self.isShowingMobileHeader = newScrollOffset <= 15;
        }
        currentScrollOffset = newScrollOffset;
      },
      hideError() {
        self.isShowingError = false;
      },
      toggleDatepickerOpen() {
        self.isDatepickerOpen = !self.isDatepickerOpen;
      },
      toggleIsCreateIssueOpen() {
        self.isCreateIssueOpen = !self.isCreateIssueOpen;

        if (self.isCreateIssueOpen) {
          self.isNotificationListOpen = false;
        }
      },
      toggleIsNotificationListOpen() {
        self.isNotificationListOpen = !self.isNotificationListOpen;

        if (self.isNotificationListOpen) {
          self.isCreateIssueOpen = false;
        }
      },
      toggleIsSearchModalOpen() {
        self.isSearchModalOpen = !self.isSearchModalOpen;
      },
      toggleIsIssuesFilterPickerOpen() {
        self.isIssuesFilterPickerOpen = !self.isIssuesFilterPickerOpen;
      },
      toggleIsRoundingPickerOpen() {
        self.isRoundingFilterPickerOpen = !self.isRoundingFilterPickerOpen;
      },
    };
  })
  .actions((self) => {
    let timeout: number;

    return {
      showError() {
        self.isShowingError = true;

        clearTimeout(timeout);

        timeout = setTimeout(() => {
          self.hideError();
        }, 3000) as any;
      },
    };
  })
  .actions((self) => ({
    afterCreate() {
      window.addEventListener('resize', self.checkDeviceResolution);
    },
    beforeDestroy() {
      window.removeEventListener('resize', self.checkDeviceResolution);
    },
    reset() {
      applySnapshot(self, {
        isMenuExpanded: true,
      });
      self.isMobileMenuOpen = false;
    },
  }));
